<script setup>
</script>

<template>
  <div class="loader-container">
    <div class="p-8 px-16 bg-white rounded-lg flex flex-col gap-3 justify-center items-center">
      <img src="../assets/images/logo.png" alt="" class="size-20" />
      <div class="loader-dots"></div>
    </div>
  </div>
</template>
  
<style scoped>
.loader-container {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
}

.loader-dots {
    --_g: no-repeat radial-gradient(farthest-side, #0d9488 90%, #0000);
  height: max-content;
  width: 5rem;
  aspect-ratio: 2.5;
  background: var(--_g), var(--_g), var(--_g), var(--_g);
  background-size: 20% 50%;
  animation: dots 1s infinite linear;
}

@keyframes dots {
  0% {
    background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 50%,
      calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 50%;
  }
  16.67% {
    background-position: calc(0 * 100% / 3) 0, calc(1 * 100% / 3) 50%,
      calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 50%;
  }
  33.33% {
    background-position: calc(0 * 100% / 3) 100%, calc(1 * 100% / 3) 0,
      calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 50%;
  }
  50% {
    background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 100%,
      calc(2 * 100% / 3) 0, calc(3 * 100% / 3) 50%;
  }
  66.67% {
    background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 50%,
      calc(2 * 100% / 3) 100%, calc(3 * 100% / 3) 0;
  }
  83.33% {
    background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 50%,
      calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 100%;
  }
  100% {
    background-position: calc(0 * 100% / 3) 50%, calc(1 * 100% / 3) 50%,
      calc(2 * 100% / 3) 50%, calc(3 * 100% / 3) 50%;
  }
}
</style>  