import { defineStore } from "pinia";
import { ref } from "vue";

export const useAuthStore = defineStore("Auth", () => {
  const isAuthenticated = ref(false);
  let accessToken = localStorage.getItem("accessToken") || null;
  let refreshToken = localStorage.getItem("refreshToken") || null;

  // isTokenValid
  if (!accessToken) {
    isAuthenticated.value = false;
  } else {
    const payload = JSON.parse(atob(accessToken.split(".")[1]));
    const exp = payload.exp * 1000; // تبدیل به میلی‌ثانیه
    isAuthenticated.value = Date.now() < exp; // بررسی اعتبار
  }

  const setTokens = ({ newAccessToken, newRefreshToken }) => {
    accessToken = newAccessToken;
    refreshToken = newRefreshToken;
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    isAuthenticated.value = true;
  };

  const clearTokens = () => {
    accessToken = null;
    refreshToken = null;
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("personInfo");
    isAuthenticated.value = false;
  };

  // const refreshAccessToken = async () => {
  //   if (!refreshToken) return;
  //   try {
  //     const response = await axios.post("/api/refresh", { refreshToken });
  //     const newTokens = {
  //       newAccessToken: response.data.accessToken,
  //       newRefreshToken: response.data.refreshToken,
  //     };
  //     setTokens(newTokens);
  //   } catch (error) {
  //     console.error("Refresh token failed:", error);
  //     clearTokens()
  //   }
  // };

  return { setTokens, clearTokens, isAuthenticated };
});
