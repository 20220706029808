<script setup>
import { ref } from "vue";

const isVisible = ref(false);
const statusMessage = ref("");
const prevStatus = ref("");
const isOnline = ref();

const checkNetworkStatus = () => {
  isOnline.value = navigator.onLine;

  if (!isOnline.value) {
    statusMessage.value = "اتصال اینترنت قطع شده است.";
    isVisible.value = true;
    prevStatus.value = "offline";
  }

  if (isOnline.value && prevStatus.value == "offline") {
    statusMessage.value = "اتصال اینترنت برقرار است.";
    isVisible.value = true;
  }

  setTimeout(() => {
    isVisible.value = false;
  }, 3000);
};

checkNetworkStatus();
window.addEventListener("online", checkNetworkStatus);
window.addEventListener("offline", checkNetworkStatus);
</script>

<template>
  <div
    v-if="isVisible"
    class="network-status text-red-600"
    :class="{ '!text-green-600 ': isOnline }"
  >
    <!-- online icon -->
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      class="*:stroke-green-600"
      v-if="isOnline"
    >
      <path
        d="M12 18.5H12.0118"
        stroke="#141B34"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.25 15.5C10.25 13.5 13.75 13.5 15.75 15.5"
        stroke="#141B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5 12.5C14.7324 9.16667 9.5 9.16667 5.5 12.5"
        stroke="#141B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 9.5C8.31579 4.16669 15.6842 4.16668 22 9.49989"
        stroke="#141B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <!-- offline icon -->
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      class="*:stroke-red-600"
      v-if="!isOnline"
    >
      <path
        d="M12 18H12.0118"
        stroke="#141B34"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.25 15C10.25 13 13.75 13 15.75 15"
        stroke="#141B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5 12C18.4235 11.9323 18.3464 11.866 18.2687 11.801M5.5 12C8.06378 9.86352 11.1338 9.0964 14 9.69866"
        stroke="#141B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 9C5.46566 6.07345 9.24828 4.75279 13 5.03799"
        stroke="#141B34"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.0002 5L16.0002 11M22.0002 11L16.0002 5"
        stroke="#141B34"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>

    <!-- status message -->
    <p>{{ statusMessage }}</p>
  </div>
</template>
  
<style scoped>
.network-status {
  position: fixed;
  top: 10px;
  right: 50%;
  transform: translateX(50%);
  background-color: #fff;
  box-shadow: 0 0 5px #ccc;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  z-index: 40;
}
</style>
  