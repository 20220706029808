import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { storeToRefs } from "pinia";
import categories from "@/utils/categories.json";

// this variable is used to keep track of the current route
let previousRoute = null;

const routes = [
  {
    path: "/",
    name: "home",
    meta: { title: "صفحه اصلی" },
    component: () => import("../views/home/Home.vue"),
  },
  {
    path: "/khatm/",
    children: [
      {
        path: "quran",
        name: "quran",
        meta: { title: categories[0].title },
        component: () => import("../views/category/Category.vue"),
      },
      {
        path: "quran-repeat",
        name: "quran-repeat",
        meta: { title: categories[1].title },
        component: () => import("../views/category/Category.vue"),
      },
      {
        path: "prayer",
        name: "prayer",
        meta: { title: categories[2].title },
        component: () => import("../views/category/Category.vue"),
      },
      {
        path: "prayer-repeat",
        name: "prayer-repeat",
        meta: { title: categories[3].title },
        component: () => import("../views/category/Category.vue"),
      },
    ],
  },
  // start test
  {
    path: "/ptest",
    component: () => import("../views/panel/components/PanelTemplate.vue"),
  },
  // end test
  {
    path: "/khatm/:category/:id",
    component: () => import("../views/ending/Ending.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: { title: "ورود", requiresGuest: true },
    component: () => import("../views/login/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: { title: "ثبت نام", requiresGuest: true },
    component: () => import("../views/register/Register.vue"),
  },
  {
    path: "/change-password",
    name: "change-password",
    meta: { title: "تغییر رمزعبور", requiresAuth: true },
    component: () => import("../views/changePassword/ChangePassword.vue"),
  },
  // {
  //   path: "/recovery-password",
  //   name: "recovery-password",
  //   meta: { title: "بازیابی رمز عبور", requiresGuest: true },
  //   component: () => import("../views/recoveryPassword/RecoveryPassword.vue"),
  // },
  {
    path: "/panel",
    name: "panel",
    component: () => import("../views/panel/Panel.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: ":tab",
        component: () => import("../views/panel/Panel.vue"),
      },
    ],
  },
  {
    path: "/profile",
    name: "profile",
    meta: { title: "پروفایل", requiresAuth: true },
    component: () => import("../views/profile/Profile.vue"),
  },
  {
    path: "/guide",
    name: "guide",
    meta: { title: "راهنما" },
    component: () => import("../views/guide/Guide.vue"),
  },
  {
    path: "/about-us",
    name: "about-us",
    meta: { title: "درباره ما" },
    component: () => import("../views/aboutUs/AboutUs.vue"),
  },
  {
    path: "/contact-us",
    name: "contact-us",
    meta: { title: "ارتباط ما" },
    component: () => import("../views/contactUs/CantactUs.vue"),
  },

  {
    path: "/:pathMatch(.*)*",
    name: "404",
    meta: { title: "404" },
    component: () => import("../views/pageNotFound/PageNotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // set new route
  previousRoute = from;

  // set meta title for each route
  let mainTitle = to.meta.title ? " | " + to.meta.title : "";
  document.title = "سراج" + mainTitle;

  // authenticate the user before starting the route and route history
  const { isAuthenticated } = storeToRefs(useAuthStore());

  // check authentication if is authenticated
  if (
    to.matched.some((record) => record.meta.requiresGuest) &&
    isAuthenticated.value
  ) {
    next({ path: "/panel" });
    return;
  }

  // check authentication if is not already authenticated
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated.value
  ) {
    next({ path: "/login" });
    return;
  }

  next();
});

export function getPreviousRoute() {
  return previousRoute;
}

export default router;
