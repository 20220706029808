import { createApp } from "vue";
import "./assets/css/index.css";
import App from "./App.vue";
//-------- import router
import router from "./router/router";
//-------- import data
import categories from "./utils/categories.json";
//-------- import date picker
import datePicker from "@alireza-ab/vue3-persian-datepicker";
//-------- import pinia
import { createPinia } from "pinia";
//-------- import swal With Config
import swalWithConfig from "./config/swalConfig";
//-------- import chart
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
//-------- chart configuration
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);
//-------- main configuration
const app = createApp(App);
app.use(createPinia());
app.use(router);
//-------- provide data
app.provide("categories", categories);
app.component("date-picker", datePicker);
//-------- for developing mode
app.config.errorHandler = (err, vm, info) => {
  // vm.$root.$emit("error", err);
  console.error(err);
  swalWithConfig.fire({
    title: "🤕 مشکلی رخ داده، لطفاً آن را رفع کنید.",
    text: err,
    icon: "error",
    showConfirmButton: false,
  });
};
//-------- mount tag with id app
app.mount("#app");